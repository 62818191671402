import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { LoginForm, SignupForm } from './components/AuthComponents';
import SEOBuilder from './components/SEOBuilder';
import './App.css';

// Wrap MainContent in a component that has access to router hooks
const MainContentWithRouter = () => {
  const navigate = useNavigate();

  const handleAuthClick = (type) => {
    navigate(type === 'signin' ? '/login' : '/signup');
  };

  return (
    <MainContent onAuthClick={handleAuthClick} />
  );
};

// Separate the main content into a component
const MainContent = ({ onAuthClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    console.log('Menu state changed:', isMenuOpen);
  }, [isMenuOpen]);

  const toggleMenu = () => {
    console.log('Toggle menu clicked. Current state:', isMenuOpen);
    setIsMenuOpen(prevState => {
      console.log('Setting new menu state:', !prevState);
      return !prevState;
    });
  };

  // Modificar el event listener para el click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log('Click event:', {
        isMenuOpen,
        hasNavMenuParent: !!event.target.closest('.nav-menu'),
        hasHamburgerParent: !!event.target.closest('.hamburger'),
      });

      if (isMenuOpen && !event.target.closest('.nav-menu') && !event.target.closest('.hamburger')) {
        console.log('Closing menu due to outside click');
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      console.log('Adding click event listener');
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      console.log('Removing click event listeners');
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isMenuOpen]);

  // Prevenir scroll cuando el menú está abierto
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  const handleAuthButtonClick = (type) => {
    setIsMenuOpen(false);
    onAuthClick(type);
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (isMenuOpen) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (isMenuOpen) {
      console.log('Disabling scroll');
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', handleScroll, { passive: false });
    }

    return () => {
      console.log('Enabling scroll');
      document.body.style.overflow = 'unset';
      document.removeEventListener('touchmove', handleScroll);
    };
  }, [isMenuOpen]);

  return (
    <div className="App">
      <nav className="navbar">
        <div className="nav-brand">Article Script Builder</div>
        <button 
          className={`hamburger ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Menu"
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
        <div className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <div className="nav-links">
            <a href="#home" onClick={() => setIsMenuOpen(false)}>Inicio</a>
            <a href="#features" onClick={() => setIsMenuOpen(false)}>Características</a>
            <a href="#testimonials" onClick={() => setIsMenuOpen(false)}>Testimonios</a>
            <a href="#pricing" onClick={() => setIsMenuOpen(false)}>Precios</a>
          </div>
          <div className="auth-buttons">
            <button className="btn-signin" onClick={() => handleAuthButtonClick('signin')}>Iniciar Sesión</button>
            <button className="btn-signup" onClick={() => handleAuthButtonClick('signup')}>Registrarse</button>
          </div>
        </div>
      </nav>

      <section id="home" className="hero-section">
        <div className="hero-content">
          <div className="gradient-blur"></div>
          <span className="hero-badge">AI-Powered Content Creation</span>
          <h1>
            Transform Your Content
            <span className="gradient-text"> Creation</span>
            <span className="hero-sparkle">✨</span>
          </h1>
          <p className="hero-description">
            Create high-quality, SEO-optimized articles in minutes with our
            <span className="highlight"> advanced AI technology</span>. Save time and
            <span className="highlight"> boost your online presence</span>.
          </p>
          <div className="hero-buttons">
            <button className="cta-button" onClick={() => onAuthClick('signup')}>
              Get Started Free
              <svg className="arrow-icon" viewBox="0 0 24 24" fill="none">
                <path d="M5 12h14m-7-7l7 7-7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
            <div className="hero-stats">
              <div className="stat-item">
                <span className="stat-number">10K+</span>
                <span className="stat-label">Active Users</span>
              </div>
              <div className="stat-divider"></div>
              <div className="stat-item">
                <span className="stat-number">1M+</span>
                <span className="stat-label">Articles Generated</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features-section">
        <h2>Key Features</h2>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-icon">🤖</div>
            <h3>AI-Powered Writing</h3>
            <p>Create high-quality content instantly with our advanced AI technology</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">📈</div>
            <h3>SEO Optimization</h3>
            <p>Boost your search rankings with intelligent keyword optimization</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">📝</div>
            <h3>Custom Templates</h3>
            <p>Choose from various pre-built templates or create your own</p>
          </div>
        </div>
      </section>

      <section id="testimonials" className="testimonials-section">
        <h2>What Our Users Say</h2>
        <div className="testimonials-grid">
          <div className="testimonial-card">
            <div className="testimonial-content">
              <p>"Transformed our content strategy completely! The AI-powered suggestions are incredibly accurate."</p>
              <div className="testimonial-author">
                <div className="author-details">
                  <h4>John Doe</h4>
                  <p>Content Manager</p>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-card">
            <div className="testimonial-content">
              <p>"Saves hours of writing time every week. The SEO optimization features are a game-changer."</p>
              <div className="testimonial-author">
                <div className="author-details">
                  <h4>Jane Smith</h4>
                  <p>SEO Specialist</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="pricing-section">
        <h2>Choose Your Plan</h2>
        <div className="pricing-grid">
          <div className="pricing-card">
            <h3>Starter</h3>
            <p className="price">$29/month</p>
            <ul>
              <li>10 Articles/month</li>
              <li>Basic SEO tools</li>
              <li>Email support</li>
            </ul>
            <button>Select Plan</button>
          </div>
          <div className="pricing-card">
            <h3>Professional</h3>
            <p className="price">$99/month</p>
            <ul>
              <li>Unlimited Articles</li>
              <li>Advanced SEO tools</li>
              <li>Priority support</li>
            </ul>
            <button>Select Plan</button>
          </div>
        </div>
      </section>

      <section className="final-cta">
        <div className="cta-container">
          <h2>Ready to Transform Your Content Strategy?</h2>
          <p>Join thousands of content creators who are already using our platform</p>
          <div className="cta-buttons">
            <button className="cta-button" onClick={() => onAuthClick('signup')}>
              Start Free Trial
            </button>
            <button className="cta-button-secondary">Schedule Demo</button>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Article Script Builder</h3>
            <p>AI-powered content creation platform for modern businesses.</p>
            <div className="social-links">
              <a href="#twitter">Twitter</a>
              <a href="#linkedin">LinkedIn</a>
              <a href="#facebook">Facebook</a>
            </div>
          </div>
          
          <div className="footer-section">
            <h4>Product</h4>
            <a href="#features">Features</a>
            <a href="#pricing">Pricing</a>
            <a href="#integrations">Integrations</a>
            <a href="#api">API</a>
          </div>

          <div className="footer-section">
            <h4>Resources</h4>
            <a href="#blog">Blog</a>
            <a href="#guides">Guides</a>
            <a href="#documentation">Documentation</a>
            <a href="#tutorials">Tutorials</a>
          </div>

          <div className="footer-section">
            <h4>Company</h4>
            <a href="#about">About</a>
            <a href="#careers">Careers</a>
            <a href="#contact">Contact</a>
            <a href="#legal">Legal</a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Article Script Builder. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

// Main App component with routing
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainContentWithRouter />} />
        <Route path="/login" element={<LoginForm onLogin={() => <Navigate to="/builder" />} />} />
        <Route path="/signup" element={<SignupForm onSignup={() => <Navigate to="/builder" />} />} />
        <Route path="/builder" element={<SEOBuilder />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { LoginForm, SignupForm } from './AuthComponents';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const SEOBuilder = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [keywords, setKeywords] = useState('');
  const [callToAction, setCallToAction] = useState('');
  const [article, setArticle] = useState('');
  const [url, setUrl] = useState('');
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [processingSince, setProcessingSince] = useState(null);
  const [history, setHistory] = useState([]);
  const [selectedRunIndex, setSelectedRunIndex] = useState(null);
  const [previousSteps, setPreviousSteps] = useState([]);
  const [language, setLanguage] = useState('es');
  const [tone, setTone] = useState('professional');
  const [backlinks, setBacklinks] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [wordCount, setWordCount] = useState('1000');
  const [title, setTitle] = useState('');  // Agregar nuevo estado para título
  const [expandedSteps, setExpandedSteps] = useState(new Set());
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login', { replace: true });
        return;
      }

      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const isExpired = Date.now() >= payload.exp * 1000;

        if (isExpired) {
          handleLogout();
        } else {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error al validar token:', error);
        handleLogout();
      }
    };

    checkAuth();
  }, [navigate]);

  const handleLogin = (token) => {
    setIsAuthenticated(true);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setHistory([]);
    setSelectedRunIndex(null);
    delete axios.defaults.headers.common['Authorization'];
    
    // Usar navigate con replace
    navigate('/login', { replace: true });
    
    // Forzar un refresh de la página
    window.location.reload();
  };

  // Modificar el useEffect que carga el historial para que solo se ejecute cuando el usuario esté autenticado
  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/articles`);
        const articles = response.data.map(article => ({
          id: article._id,
          timestamp: new Date(article.createdAt).toLocaleString(),
          title: article.title || article.formData.title,
          keywords: article.keywords,
          steps: article.steps,
          formData: article.formData
        }));
        setHistory(articles);
      } catch (error) {
        console.error('Error fetching history:', error);
        if (error.response?.status === 401 || error.response?.status === 403) {
          handleLogout(); // Si el token expiró o es inválido, hacer logout
        }
      }
    };

    if (isAuthenticated) {
      fetchHistory();
    }
  }, [isAuthenticated]); // Agregar isAuthenticated como dependencia

  const callChatGPT = async (prompt, role) => {
    try {
      const response = await axios.post(`${API_URL}/api/chatgpt`, { prompt, role });
      return response.data.message;
    } catch (error) {
      console.error('Error calling ChatGPT:', error);
      return 'Error al procesar la solicitud';
    }
  };

  const getStepStatusStyle = (status) => {
    switch (status) {
      case 'completed':
        return 'bg-emerald-500/10 border-emerald-500 hover:bg-emerald-500/20 transition-colors';
      case 'processing':
        return 'bg-indigo-500/10 border-indigo-500 animate-pulse';
      case 'waiting':
        return 'bg-amber-500/10 border-amber-500';
      default:
        return 'bg-slate-800 border-slate-700';
    }
  };

  const getStepPrompt = (step, basePrompt, previousOutput) => {
    const chainedPrompt = `
Base Information:
${basePrompt}

${previousOutput ? `Previous Step Output:
${previousOutput}

` : ''}
Role: You are a specialized ${step.role}. 
Task: ${step.task}
Required Output Format: ${step.outputFormat}
`;
    return chainedPrompt;
  };

  // Modify saveToSanity function to include formData
  const saveToSanity = async (run) => {
    try {
      const response = await axios.post('http://localhost:3001/api/save-article', {
        title: run.title,
        keywords: run.keywords,
        steps: run.steps,
        metadata: {
          language: run.formData.language,
          tone: run.formData.tone,
          targetAudience: run.formData.targetAudience,
          wordCount: parseInt(run.formData.wordCount)
        },
        formData: run.formData // Asegurarse de enviar formData completo
      });
      
      // After successful save, refresh the history
      const historyResponse = await axios.get('http://localhost:3001/api/articles');
      const articles = historyResponse.data.map(article => ({
        id: article._id,
        timestamp: new Date(article.createdAt).toLocaleString(),
        title: article.title || article.formData.title,
        keywords: article.keywords,
        steps: article.steps,
        formData: article.formData
      }));
      setHistory(articles);
      
      console.log('Saved to Sanity:', response.data);
    } catch (error) {
      console.error('Error saving to Sanity:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPreviousSteps([]);
    
    let previousStepOutput = null;
    let stepsResults = [];  // Move this declaration to the top

    const newRun = {
      id: Date.now(),
      timestamp: new Date().toLocaleString(),
      keywords,
      title, // Agregar título al run
      formData: { // Store form data with the run
        title, // Agregar título a formData
        keywords,
        callToAction,
        language,
        tone,
        backlinks,
        targetAudience,
        wordCount,
        url,
        article
      },
      steps: [
        { 
          title: 'Extracción de información',
          status: 'processing',
          role: 'SEO Research Specialist',
          task: 'Extract and analyze key information about the topic. Focus on search intent, main themes, and current trends.',
          outputFormat: 'JSON format with: mainThemes[], searchIntent, trends[], keyFindings[]'
        },
        { 
          title: 'Análisis de competidores',
          status: 'waiting',
          role: 'Competitive Analysis Expert',
          task: 'Based on the research provided, analyze top competing content and identify gaps and opportunities.',
          outputFormat: 'JSON format with: topCompetitors[], contentGaps[], opportunities[], recommendedAngles[]'
        },
        { 
          title: 'Redacción de contenido SEO',
          status: 'waiting',
          role: 'SEO Content Strategist',
          task: 'Using the research and competitive analysis, create a detailed content outline and article.',
          outputFormat: 'Markdown format with clear structure following SEO best practices'
        },
        { 
          title: 'Control de calidad',
          status: 'waiting',
          role: 'SEO Quality Assurance Specialist',
          task: 'Review the content against SEO best practices and provided research. Suggest specific improvements.',
          outputFormat: 'JSON format with: score, checklistResults[], improvements[], optimizationTips[]'
        },
        { 
          title: 'Meta y keywords',
          status: 'waiting',
          role: 'Technical SEO Specialist',
          task: 'Generate optimized meta information based on the final content and all previous analysis.',
          outputFormat: 'JSON format with: metaTitle, metaDescription, keywords[], structuredData'
        },
        { 
          title: 'Artículo Final Optimizado',
          status: 'waiting',
          role: 'Senior Content Integration Specialist',
          task: `Create a highly engaging and viral-optimized article by integrating all previous analyses and following these guidelines:

Content Structure Requirements:
1. Use proven viral content formats (listicles, how-to, case study, or problem-solution)
2. Include engaging subheadings that generate curiosity
3. Add statistical data and expert quotes when relevant
4. Use bullet points and numbered lists for better readability
5. Include "share-worthy" insights or takeaways
6. Add content hooks throughout the article

Engagement Elements:
- Start with a powerful hook/opening
- Include relevant examples and case studies
- Add "Tweet this" worthy quotes or statistics
- Use the "AIDA" formula (Attention, Interest, Desire, Action)
- Include mini-conclusions after each major section
- End with a compelling call-to-action

SEO Requirements:
- Implement all previous optimizations and meta suggestions
- Natural keyword placement in H2 and H3 headlines
- Optimize for featured snippets
- Include FAQ section if relevant
- Natural integration of backlinks

Use all the insights from:
1. Research Analysis: ${stepsResults[0] || 'Not available'}
2. Competitive Analysis: ${stepsResults[1] || 'Not available'}
3. Initial Content: ${stepsResults[2] || 'Not available'}
4. QA Suggestions: ${stepsResults[3] || 'Not available'}
5. Meta Information: ${stepsResults[4] || 'Not available'}`,
          outputFormat: `Markdown format including:

# [Engaging Title with Primary Keyword]

> [Compelling Hook or Quote]

## Introduction
[Engaging opening with curiosity gap]

## [Main Section 1]
[Content with examples/data]

## [Main Section 2]
[Content with examples/data]

## [Main Section 3]
[Content with examples/data]

## Key Takeaways
- [Shareable point 1]
- [Shareable point 2]
- [Shareable point 3]

## FAQ Section
[If relevant to the topic]

## Conclusion
[Summary and strong CTA]

[Include natural backlink placements throughout the content]`
        }
      ]
    };

    // Reset steps but keep form data
    const resetSteps = () => {
      setSteps([]);
      setPreviousSteps([]);
      setActiveStep(null);
      setProcessingSince(null);
    };

    try {
      setHistory(prev => [...prev, newRun]);
      setSelectedRunIndex(history.length);
      setSteps(newRun.steps);

      const basePrompt = `
Content Parameters:
- Keywords: ${keywords}
- Call to Action: ${callToAction}
- URL: ${url}
- Language: ${language}
- Tone of Voice: ${tone}
- Target Audience: ${targetAudience}
- Word Count Target: ${wordCount} words
- Required Backlinks: ${backlinks}
- Existing Article: ${article}

Additional Requirements:
- Maintain consistent ${tone} tone throughout the content
- Write in ${language} language
- Include natural placements for specified backlinks
- Adapt content style for ${targetAudience}
- Optimize for readability and engagement
`;

      let previousStepOutput = null;
      let stepsResults = [];

      for (let i = 0; i < newRun.steps.length; i++) {
        const step = newRun.steps[i];
        setActiveStep(i);
        setProcessingSince(new Date());
        
        setHistory(prev => {
          const newHistory = [...prev];
          const currentRun = newHistory[history.length];
          currentRun.steps = currentRun.steps.map((s, index) => ({
            ...s,
            status: index === i ? 'processing' : index === i + 1 ? 'waiting' : s.status
          }));
          return newHistory;
        });

        let content;
        if (i === newRun.steps.length - 1) {
          const integrationPrompt = `
${basePrompt}

You are a Senior Content Integration Specialist. Your task is to create the final, fully optimized version of the article by integrating all previous work:

Previous Steps Results:
1. Research Analysis: ${stepsResults[0] || 'Not available'}
2. Competitive Analysis: ${stepsResults[1] || 'Not available'}
3. Initial Content: ${stepsResults[2] || 'Not available'}
4. QA Suggestions: ${stepsResults[3] || 'Not available'}
5. Meta Information: ${stepsResults[4] || 'Not available'}

Create the definitive version that incorporates all insights and optimizations while maintaining readability and engagement.

Required Output Format:
${step.outputFormat}
`;
          content = await callChatGPT(integrationPrompt, step.role);
          
          // Actualizar el último paso inmediatamente
          newRun.steps[i] = {
            ...newRun.steps[i],
            content,
            status: 'completed',
            isExpanded: true
          };
          // Expandir automáticamente el último paso completado
          setExpandedSteps(new Set([i]));
        } else {
          const chainedPrompt = getStepPrompt(step, basePrompt, previousStepOutput);
          content = await callChatGPT(chainedPrompt, step.role);
          stepsResults[i] = content;
          previousStepOutput = content;
        }

        setHistory(prev => {
          const newHistory = [...prev];
          const currentRun = newHistory[history.length];
          currentRun.steps = currentRun.steps.map((s, index) => ({
            ...s,
            content: index === i ? content : s.content,
            status: index === i ? 'completed' : s.status,
            // Solo expandir el último paso cuando se complete
            isExpanded: index === i && i === newRun.steps.length - 1
          }));
          return newHistory;
        });
      }

      // Asegurarse de que newRun tenga todos los pasos actualizados antes de guardar
      const finalSteps = newRun.steps.map(step => ({
        title: step.title,
        content: step.content,
        status: step.status
      }));

      // Guardar con los pasos finales
      await saveToSanity({
        ...newRun,
        steps: finalSteps
      });

      setActiveStep(null);
      setProcessingSince(null);
      setLoading(false);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setLoading(false);
      resetSteps();
    }
  };

  // Add function to load previous run data
  const loadPreviousRun = (run) => {
    if (run.formData) {
      setTitle(run.formData.title || '');  // Cargar título
      setKeywords(run.formData.keywords || '');
      setCallToAction(run.formData.callToAction || '');
      setLanguage(run.formData.language || 'es');
      setTone(run.formData.tone || 'professional');
      setBacklinks(run.formData.backlinks || '');
      setTargetAudience(run.formData.targetAudience || '');
      setWordCount(run.formData.wordCount || '1000');
      setUrl(run.formData.url || '');
      setArticle(run.formData.article || '');
      setExpandedSteps(new Set()); // Reset expanded steps
    }
  };

  const copyToClipboard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      // Opcional: Aquí podrías agregar un toast/notification de éxito
    } catch (err) {
      console.error('Error al copiar:', err);
    }
  };

  const deleteArticle = async (id) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este artículo?')) {
      try {
        await axios.delete(`http://localhost:3001/api/articles/${id}`);
        // Actualizar el historial después de borrar
        const response = await axios.get('http://localhost:3001/api/articles');
        const articles = response.data.map(article => ({
          id: article._id,
          timestamp: new Date(article.createdAt).toLocaleString(),
          title: article.title || article.formData.title,
          keywords: article.keywords,
          steps: article.steps,
          formData: article.formData
        }));
        setHistory(articles);
        setSelectedRunIndex(null);
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  if (!isAuthenticated) {
    return showLogin ? (
      <LoginForm onLogin={handleLogin} onToggleForm={() => setShowLogin(false)} />
    ) : (
      <SignupForm onSignup={handleLogin} onToggleForm={() => setShowLogin(true)} />
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 text-white p-4 md:p-8 flex flex-col">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-4xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-emerald-500">
          SEO Article Builder
        </h1>
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-slate-700 text-white rounded-lg hover:bg-slate-600"
        >
          Logout
        </button>
      </div>
      
      <div className="bg-slate-800/50 backdrop-blur-sm p-6 rounded-xl shadow-2xl mb-8 border border-slate-700/50">
        <h2 className="text-2xl font-bold mb-6 text-slate-200">Configuración de contenido</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Título del proyecto</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                placeholder="Nombre para identificar este contenido..."
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Keywords</label>
              <input
                type="text"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                placeholder="Palabras clave principales..."
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Call to Action</label>
              <input
                type="text"
                value={callToAction}
                onChange={(e) => setCallToAction(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                placeholder="Llamada a la acción..."
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Audiencia objetivo</label>
              <input
                type="text"
                value={targetAudience}
                onChange={(e) => setTargetAudience(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                placeholder="Ej: 'Profesionales de marketing, 25-45 años'"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Backlinks</label>
              <textarea
                value={backlinks}
                onChange={(e) => setBacklinks(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all h-24"
                placeholder="Un enlace por línea, formato: URL|Texto ancla"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Número de palabras objetivo</label>
              <input
                type="number"
                value={wordCount}
                onChange={(e) => setWordCount(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                min="100"
                step="100"
                placeholder="Número de palabras objetivo"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">URL de la página</label>
              <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
                placeholder="URL de la página"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Artículo existente</label>
              <textarea
                value={article}
                onChange={(e) => setArticle(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all h-32"
                placeholder="Artículo existente (opcional)"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Idioma</label>
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
              >
                <option value="es">Español</option>
                <option value="en">English</option>
                <option value="pt">Português</option>
              </select>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium text-slate-300">Tono</label>
              <select
                value={tone}
                onChange={(e) => setTone(e.target.value)}
                className="w-full p-3 bg-slate-700/50 text-white rounded-lg border border-slate-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-500/20 transition-all"
              >
                <option value="professional">Profesional</option>
                <option value="casual">Casual</option>
                <option value="formal">Formal</option>
                <option value="friendly">Amistoso</option>
                <option value="academic">Académico</option>
              </select>
            </div>
          </div>
          <button 
            type="submit" 
            className="col-span-full p-4 bg-gradient-to-r from-indigo-600 to-indigo-500 text-white rounded-lg hover:from-indigo-500 hover:to-indigo-400 transform hover:scale-[1.02] transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none shadow-lg"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center gap-2">
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none"/>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
                Generando...
              </span>
            ) : 'Generar Artículo'}
          </button>
        </form>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="md:col-span-1 space-y-4">
          <h2 className="text-xl font-bold mb-4 text-slate-200">Historial</h2>
          <div className="space-y-3 max-h-[600px] overflow-y-auto pr-2 custom-scrollbar">
            {history.map((run, index) => (
              <div
                key={run.id}
                className={`p-4 rounded-lg cursor-pointer transition-all transform hover:scale-[1.02] ${
                  selectedRunIndex === index
                    ? 'bg-indigo-500/20 border border-indigo-500 shadow-lg shadow-indigo-500/10'
                    : 'bg-slate-800/50 hover:bg-slate-700/50 border border-slate-700/50'
                }`}
              >
                <div className="flex justify-between items-start">
                  <div
                    onClick={() => {
                      setSelectedRunIndex(index);
                      loadPreviousRun(run);
                    }}
                    className="flex-1"
                  >
                    <div className="font-semibold">{run.title || 'Sin título'}</div>
                    <div className="text-sm text-slate-400 mt-1">{run.keywords}</div>
                    <div className="text-sm text-slate-400">{run.timestamp}</div>
                    <div className="text-sm mt-2">
                      {run.steps.filter(s => s.status === 'completed').length} / {run.steps.length} pasos completados
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteArticle(run.id);
                    }}
                    className="p-2 text-slate-400 hover:text-red-400 transition-colors"
                    title="Eliminar artículo"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="md:col-span-2">
          {selectedRunIndex !== null && history[selectedRunIndex] && (
            <div className="space-y-4">
              <h2 className="text-xl font-bold mb-4 text-slate-200">Resultados</h2>
              <div className="space-y-3">
                {history[selectedRunIndex].steps.map((step, index) => (
                  <div 
                    key={index} 
                    className={`border rounded-xl transition-all duration-300 ${getStepStatusStyle(step.status)}`}
                  >
                    <button
                      onClick={() => {
                        if (step.status === 'completed') {
                          const newExpandedSteps = new Set(expandedSteps);
                          if (expandedSteps.has(index)) {
                            newExpandedSteps.delete(index);
                          } else {
                            newExpandedSteps.add(index);
                          }
                          setExpandedSteps(newExpandedSteps);
                        }
                      }}
                      className="w-full px-6 py-4 flex items-center justify-between text-left"
                    >
                      <div className="flex items-center gap-4">
                        <span className="text-xl font-semibold">{step.title}</span>
                        {step.status === 'processing' && (
                          <span className="text-sm text-indigo-400 animate-pulse">Procesando...</span>
                        )}
                        {step.status === 'waiting' && (
                          <span className="text-sm text-amber-400">En espera...</span>
                        )}
                        {step.status === 'completed' && (
                          <span className="text-sm text-emerald-400">Completado</span>
                        )}
                      </div>
                      <svg
                        className={`w-6 h-6 transform transition-transform ${expandedSteps.has(index) ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    
                    {((step.status === 'completed' && expandedSteps.has(index)) && step.content) && (
                      <div className="px-6 py-4 border-t border-slate-700">
                        {(step.title === 'Redacción de contenido SEO' || step.title === 'Artículo Final Optimizado') ? (
                          <div className="prose prose-invert max-w-none">
                            {step.title === 'Artículo Final Optimizado' && (
                              <button
                                onClick={() => copyToClipboard(step.content)}
                                className="float-right p-2 text-slate-400 hover:text-emerald-400 transition-colors"
                                title="Copiar contenido"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                                  />
                                </svg>
                              </button>
                            )}
                            <ReactMarkdown>{step.content}</ReactMarkdown>
                          </div>
                        ) : (
                          <p className="whitespace-pre-wrap">{step.content}</p>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add footer at the end */}
      <footer className="mt-auto pt-8 pb-4 text-center text-sm text-slate-400">
        <div className="flex items-center justify-center gap-2">
          <span>Copyright © {new Date().getFullYear()} Manguito Studios</span>
          <span className="text-slate-600">|</span>
          <div className="flex items-center gap-1">
            <span>Powered by</span>
            <a 
              href="https://hiveflow.ai" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-indigo-400 hover:text-indigo-300 transition-colors"
            >
              Hiveflow
            </a>
          </div>
        </div>
      </footer>

      <style jsx global>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: rgba(148, 163, 184, 0.1);
          border-radius: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: rgba(148, 163, 184, 0.2);
          border-radius: 4px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: rgba(148, 163, 184, 0.3);
        }
      `}</style>
    </div>
  );
};

export default SEOBuilder;